<template>
  <div id="app">
    <loader :show="show" />
    <v-container fluid class="text-center mt-n4">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong><h1>Participación de encuesta con Referencia II</h1></strong>
            <table style="margin-left: auto; margin-right: auto; border: 1px solid black; " >
            <tr>
              <td style="padding:5px;">
                <v-icon size="22" color="red">far fa-angry</v-icon>
                <p style="font-size:10px;">Nunca</p>
              </td>
              <td style="padding:5px;">
                <v-icon size="22" color="orange">far fa-frown</v-icon>
                <p style="font-size:10px;">Casi nunca</p>
              </td>
              <td style="padding:5px;">
                <v-icon size="22">fas fa-flushed</v-icon><br>
                <p style="font-size:10px;">Algunas veces</p>
              </td>
              <td style="padding:5px;">
                <v-icon size="22" color="yellow accent-4">far fa-smile</v-icon>
                <p style="font-size:10px;">Casi siempre</p>
              </td>
              <td style="padding:5px;">
                <v-icon size="22" color="green">fas fa-laugh-beam</v-icon>
                <p style="font-size:10px;">Siempre</p>
              </td>
            </tr>
          </table>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center mt-n5" v-if="primera">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección I.-"Las condiciones de su centro de trabajo, así
            como la cantidad y ritmo de trabajo."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionI"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span>
                    <span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange">far fa-frown</v-icon>
                    </span>
                    <span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span>
                    <span>
                        <v-radio value="2" color="yellow accent-4"></v-radio>
                        <v-icon size="22" color="yellow accent-4">far fa-smile</v-icon>
                      </span>
                    <span>
                      <v-radio value="1" color="green"></v-radio>
                      <v-icon size="22" color="green">fas fa-laugh-beam</v-icon>
                    </span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="segunda">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección II.- "Las actividades que realiza en su trabajo y
            las responsabilidades que tiene."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionII"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span
                    ><span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange"
                        >far fa-frown</v-icon
                      ></span
                    ><span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span
                    ><span>
                      <v-radio value="2" color="yellow accent-4"></v-radio
                      ><v-icon size="22" color="yellow accent-4"
                        >far fa-smile</v-icon
                      ></span
                    ><span>
                      <v-radio value="1" color="green"></v-radio
                      ><v-icon size="22" color="green"
                        >fas fa-laugh-beam</v-icon
                      ></span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion2()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="tercera">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección III.- "El tiempo destinado a su trabajo y sus
            responsabilidades familiares."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionIII"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span
                    ><span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange"
                        >far fa-frown</v-icon
                      ></span
                    ><span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span
                    ><span>
                      <v-radio value="2" color="yellow accent-4"></v-radio
                      ><v-icon size="22" color="yellow accent-4"
                        >far fa-smile</v-icon
                      ></span
                    ><span>
                      <v-radio value="1" color="green"></v-radio
                      ><v-icon size="22" color="green"
                        >fas fa-laugh-beam</v-icon
                      ></span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion3()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="cuarta">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección IV.- "Las decisiones que puede tomar en su
            trabajo."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionIV"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span
                    ><span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange"
                        >far fa-frown</v-icon
                      ></span
                    ><span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span
                    ><span>
                      <v-radio value="2" color="yellow accent-4"></v-radio
                      ><v-icon size="22" color="yellow accent-4"
                        >far fa-smile</v-icon
                      ></span
                    ><span>
                      <v-radio value="1" color="green"></v-radio
                      ><v-icon size="22" color="green"
                        >fas fa-laugh-beam</v-icon
                      ></span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion4()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="quinta">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección V.- "La capacitación e información que recibe
            sobre su trabajo."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionV"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span
                    ><span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange"
                        >far fa-frown</v-icon
                      ></span
                    ><span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span
                    ><span>
                      <v-radio value="2" color="yellow accent-4"></v-radio
                      ><v-icon size="22" color="yellow accent-4"
                        >far fa-smile</v-icon
                      ></span
                    ><span>
                      <v-radio value="1" color="green"></v-radio
                      ><v-icon size="22" color="green"
                        >fas fa-laugh-beam</v-icon
                      ></span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion5()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="sexta">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección VI.- "Relaciones con sus compañeros de trabajo y
            su jefe."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionVI"
          :footer-props="{
            'items-per-page-text': 'Preguntas',
            'items-per-page-options': [20],
            'loading-text': 'Obteniendo datos... Por favor espere',
            'no-data-text': 'No hay datos...',
            'no-results-text': 'No se encontraron incidencias',
          }"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span
                    ><span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange"
                        >far fa-frown</v-icon
                      ></span
                    ><span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span
                    ><span>
                      <v-radio value="2" color="yellow accent-4"></v-radio
                      ><v-icon size="22" color="yellow accent-4"
                        >far fa-smile</v-icon
                      ></span
                    ><span>
                      <v-radio value="1" color="green"></v-radio
                      ><v-icon size="22" color="green"
                        >fas fa-laugh-beam</v-icon
                      ></span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion6()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="septima">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección VII.- "La atención a clientes y usuarios."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionVII"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span
                    ><span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange"
                        >far fa-frown</v-icon
                      ></span
                    ><span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span
                    ><span>
                      <v-radio value="2" color="yellow accent-4"></v-radio
                      ><v-icon size="22" color="yellow accent-4"
                        >far fa-smile</v-icon
                      ></span
                    ><span>
                      <v-radio value="1" color="green"></v-radio
                      ><v-icon size="22" color="green"
                        >fas fa-laugh-beam</v-icon
                      ></span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion7()">
            Siguiente
          </button>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid class="text-center" v-if="octava">
      <v-row justify="start">
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <strong>
            Preguntas sección VIII.- "Las actitudes de los trabajadores que
            supervisa."</strong
          >
        </v-col>
      </v-row>
      <v-card>
        <v-data-table
          :headers="headers"
          :items="seccionVIII"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="#ffffff" icon="warning">
              <p style="color: black">No hay datos en sistema ...</p>
            </v-alert>
          </template>
          <template v-slot:body="{ items }">
            <tbody>
              <tr
                v-for="item in items"
                :key="item.Id"
                :class="{ selectedRow: item === selectedItem }"
              >
                <td class="text-sm-center">
                  {{ item.consecutivoGuiaPregunta }}
                </td>
                <td class="text-sm-center">{{ item.pregunta }}</td>
                <td class="text-sm-center">
                  <v-radio-group
                    v-model="item.respuesta"
                    row
                    class="text-sm-center justify-center align-items-center align-content-center"
                  >
                    <span>
                      <v-radio value="5" color="red"> </v-radio
                      ><v-icon size="22" color="red">far fa-angry</v-icon></span
                    ><span>
                      <v-radio value="4" color="orange"></v-radio
                      ><v-icon size="22" color="orange"
                        >far fa-frown</v-icon
                      ></span
                    ><span>
                      <v-radio value="3" color="grey"></v-radio>
                      <v-icon size="22">fas fa-flushed</v-icon></span
                    ><span>
                      <v-radio value="2" color="yellow accent-4"></v-radio
                      ><v-icon size="22" color="yellow accent-4"
                        >far fa-smile</v-icon
                      ></span
                    ><span>
                      <v-radio value="1" color="green"></v-radio
                      ><v-icon size="22" color="green"
                        >fas fa-laugh-beam</v-icon
                      ></span
                    >
                  </v-radio-group>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
          <button class="botonAmarillo mt-5" @click="validacion8()">
            Guardar
          </button>
        </v-col>
      </v-row>
    </v-container>

    <!--Error-->
    <v-dialog v-model="error" width="400">
      <v-card>
        <v-card-title class="red white--text">
          <span class="headline">Error</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="center">{{ respuesta }}</h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="error = false">Corregir</v-btn>
        </v-card-actions>
      </v-card> </v-dialog
    ><!--Confirmacion-->
    <v-dialog v-model="confirmation" width="400">
      <v-card>
        <v-card-title class="green white--text">
          <span class="headline">Confirmación</span>
        </v-card-title>
        <v-card-text class="text-center" v-if="bandera">
          <br />
          <h2>
            No fue posible guardar su respuesta, favor de intentar más tarde
          </h2>
          <br />
          <br />
        </v-card-text>
        <v-card-text v-else class="text-center">
          <br />
          <h2>¡Felicidades ha terminado la encuesta!</h2>
          <br />
          <h3 class="text-justify">
            Agradecemos tu participación en la encuesta con referencia II.
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="finalizar()">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Instrucciones-->
    <v-dialog v-model="instrucciones" width="400" scrollable>
      <v-card>
        <v-card-title class="success white--text">
          <span class="headline">Instrucciones</span>
        </v-card-title>
        <v-card-text>
          <br />
          <h3 class="text-md-justify text-sm-justify text-lg-justify">
            Agrademos tu participación en la encuesta, a continuación aparecerán
            5 valores diferentes para cada respuesta donde la carita
            alegre(Verde) indica que Estas de acuerdo o siempre realizas la
            actividad y la carita enojada(roja) responde que nunca realizas la
            actividad o que estas totalmente desacuerdo. ¡No esperes más
            comienza!
          </h3>
          <br />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            text
            @click="
              instrucciones = false;
              primera = true;
            "
            >Empezar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// Comentario prueba
import Loader from "@/components/Loader";
export default {
  components: {
    Loader,
  },
  data() {
    return {
      show: false,
      selectedItem: null,
      headers: [
        {
          text: "Id",
          align: "center",
          sortable: false,
          value: "id",
        },
        {
          text: "Pregunta",
          align: "center",
          sortable: false,
          value: "pregunta",
        },
        {
          text: "Opinion",
          align: "center",
          sortable: false,
          value: "respuesta",
        },
      ],
      seccionI: [],
      seccionII: [],
      seccionIII: [],
      seccionIV: [],
      seccionV: [],
      seccionVI: [],
      seccionVII: [],
      seccionVIII: [],
      datosSecciones: [],
      error: false,
      respuesta: "",
      confirmation: false,
      instrucciones: true,
      primera: false,
      segunda: false,
      tercera: false,
      cuarta: false,
      quinta: false,
      sexta: false,
      septima: false,
      octava: false,
      questions: [],
      secciones: [],
      esJefe: 0,
      prestaServicio: 0,
      idEmpleado: localStorage.empleadoIdGlobal,
      idEncuesta: 0,
      bandera: false,
    };
  },
  methods: {
    /*
    Consultamos plimero los datos del empleado, para ver si mostramos seccion
    de servicio y seccion de preguntas si es jefe---secciones obligatorias 1-6
    */
    finalizar() {
      this.confirmation = false;
      this.bandera = false;
      this.$router.push("/directory");
    },
    listar() {
      var posicionIdCuestionario = localStorage.cuestionario.indexOf(
        "idCuestionario="
      );
      var posicionIdEmpleado = localStorage.cuestionario.indexOf(
        "&idEmpleado="
      );
      this.idEncuesta = localStorage.cuestionario.slice(
        posicionIdCuestionario + 15,
        posicionIdEmpleado
      );
      this.show = true;
      axios
        .get(Server + "/empleados/" + this.idEmpleado)
        .then((response) => {
          this.esJefe = response.data.puesto.esJefe;
          this.prestaServicio = response.data.puesto.puestoServicioCliente;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
        });
      this.show = true;
      /*
      Consultamos preguntas de la guia 2
      */
      this.seccionI = [];
      axios
        .get(Server + "/climaLaboralPreguntas/guia/" + 2)
        .then((response) => {
          var auxSeccion = response.data;
          var temp = [];
          auxSeccion.forEach((value, index) => {
            if (value.seccion != "") {
              temp.push(value.seccion);
            }
          });
          const myObj = {};
          this.secciones = [];
          temp.forEach((el) => {
            // comprobamos si el valor existe en el objeto
            if (!(el in myObj)) {
              // si no existe creamos ese valor y lo añadimos al array final, y si sí existe no lo añadimos
              myObj[el] = true;
              this.secciones.push(el);
            }
          });
          //console.log(this.secciones);
          for (var i = 0; i < this.secciones.length; i++) {
            this.datosSecciones[i] = [];
            auxSeccion.forEach((value) => {
              if (value.seccion === this.secciones[i]) {
                this.datosSecciones[i].push({
                  id: value.id,
                  consecutivoGuiaPregunta: value.consecutivoGuiaPregunta,
                  pregunta: value.pregunta,
                  respuesta: null,
                  seccion: value.seccion,
                });
              }
            });
          }
          //console.log(this.datosSecciones);
          this.seccionI = this.datosSecciones[0];
          this.seccionII = this.datosSecciones[1];
          this.seccionIII = this.datosSecciones[2];
          this.seccionIV = this.datosSecciones[3];
          this.seccionV = this.datosSecciones[4];
          this.seccionVI = this.datosSecciones[5];
          this.seccionVII = this.datosSecciones[6];
          this.seccionVIII = this.datosSecciones[7];
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
        });
    },
    validacion() {
      //Aux arreglo de preguntas que no han contestado
      var aux = this.seccionI.filter((pregunta) => pregunta.respuesta === null);
      //han contestado todas las preguntas
      if (aux.length == 0) {
        this.primera = false;
        this.segunda = true;
      }
      //Falto alguna pregunta por contestar
      else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion2() {
      //Aux arreglo de preguntas que no han contestado
      var aux = this.seccionII.filter(
        (pregunta) => pregunta.respuesta === null
      ); //han contestado todas las preguntas

      if (aux.length == 0) {
        this.segunda = false;
        this.tercera = true;
      }
      //Falto alguna pregunta por contestar
      else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion3() {
      //Aux arreglo de preguntas que no han contestado
      var aux = this.seccionIII.filter(
        (pregunta) => pregunta.respuesta === null
      );
      //han contestado todas las preguntas
      if (aux.length == 0) {
        this.tercera = false;
        this.cuarta = true;
      }
      //Falto alguna pregunta por contestar
      else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion4() {
      //Aux arreglo de preguntas que no han contestado
      var aux = this.seccionIV.filter(
        (pregunta) => pregunta.respuesta === null
      );
      //han contestado todas las preguntas
      if (aux.length == 0) {
        this.cuarta = false;
        this.quinta = true;
      }
      //Falto alguna pregunta por contestar
      else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion5() {
      var aux = this.seccionV.filter((pregunta) => pregunta.respuesta === null);
      if (aux.length == 0) {
        this.quinta = false;
        this.sexta = true;
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion6() {
      var aux = this.seccionVI.filter(
        (pregunta) => pregunta.respuesta === null
      );
      if (aux.length == 0) {
        this.sexta = false;
        //si no presto servicio a cliente y no soy jefe
        if (this.prestaServicio == null && this.esJefe == null) {
          this.seccionVII = [];
          this.seccionVIII = [];
          this.guardar();
        }
        //presto servicios
        if (this.prestaServicio == 1) {
          this.septima = true;
        }
        //si no presto servicio a cliente y soy jefe
        if (
          this.prestaServicio == 0 ||
          (this.prestaServicio == null && this.esJefe == 1)
        ) {
          this.seccionVII = [];
          this.octava = true;
        }
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion7() {
      var aux = this.seccionVII.filter(
        (pregunta) => pregunta.respuesta === null
      );
      if (aux.length == 0) {
        this.septima = false;
        if (this.esJefe == 0 || this.esJefe == null) {
          this.seccionVIII = [];
          this.guardar();
        } else {
          this.octava = true;
        }
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    validacion8() {
      var aux = this.seccionVIII.filter(
        (pregunta) => pregunta.respuesta === null
      );
      if (aux.length == 0) {
        this.guardar();
      } else {
        this.respuesta = "Es necesario contestar todas las preguntas";
        this.error = true;
      }
    },
    guardar() {
      this.show = true;
      this.questions = this.seccionI.concat(
        this.seccionII.concat(
          this.seccionIII.concat(
            this.seccionIV.concat(
              this.seccionV.concat(
                this.seccionVI.concat(this.seccionVII.concat(this.seccionVIII))
              )
            )
          )
        )
      );
      this.questions.forEach((element, index) => {
        delete element.consecutivoGuiaPregunta;
        delete element.pregunta;
        delete element.seccion;
      });
      var allQuestions = [];
      this.questions.forEach((element, index) => {
        var pregunta = new Object();
        pregunta.IdPregunta = element.id;
        pregunta.Respuesta =
          element.respuesta == "1" ? 1 : parseInt(element.respuesta);
        allQuestions.push(pregunta);
      });
      //console.log("Elementos finales", allQuestions);
      axios
        .post(
          Server +
            "/climaLaboralResultados/guardar-resultados-encuesta-dostres",
          {
            IdEncuesta: this.idEncuesta,
            Preguntas: allQuestions,
          }
        )
        .then((response) => {
          this.show = false;
          this.octava = false;
          this.confirmation = true;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          this.bandera = true;
          this.confirmation = true;
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>
<style>
.v-input--radio-group__input {
  justify-content: center;
}
</style>
